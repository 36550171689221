import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Invoices from "./pages/Invoices";
import Packages from "./pages/Packages";
import Providers from "./pages/Providers";
import Settings from "./pages/Settings";
import Definitions from "./pages/Definitions";
import Layout from "./components/layout";
import { LoadingProvider } from "./contexts/LoadingContext";

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.substring(1); // "/" işaretinden sonra gelen kısmı alır
    document.title = path
      ? `${path.charAt(0).toUpperCase()}${path.slice(1)} | esimzo`
      : "Home | esimzo";
  }, [location]);
  return (
    <LoadingProvider>
      <Routes>
        {/* Login rotası için Layoutsız */}
        <Route path="/login" element={<Login />} />

        {/* Layout ile diğer rotalar */}
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/providers" element={<Providers />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/definitions" element={<Definitions />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </LoadingProvider>
  );
};

const AppWrapper: React.FC = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
