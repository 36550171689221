import React from "react";
import Header from "./Header";

type Props = {
  name: string;
  placeholder: string;
  value: string;
  onChange: any;
  onKeyDown: any;
};

const Input: React.FC<Props> = ({
  name,
  placeholder,
  value,
  onChange,
  onKeyDown,
}) => {
  return (
    <div className="flex flex-col space-y-3">
      <Header name={name} />
      <input
        className="border p-2 rounded-sm w-full"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default Input;
