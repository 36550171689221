import React, { useCallback, useState } from "react";
import { FaSearch } from "react-icons/fa";

const SearchInput = ({
  onSearch,
  pagination,
}: {
  onSearch: any;
  pagination: any;
}) => {
  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = useCallback(
    debounce(
      (query: string) => onSearch({ q: query, take: pagination.take }),
      300
    ),
    [onSearch]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  return (
    <div className="flex items-center max-w-[200px]">
      <input
        type="text"
        onChange={handleChange}
        placeholder="Search..."
        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );
};

export default SearchInput;
