import React, { useEffect, useState } from "react";
import { getUserList } from "../../services/apiService";
import dayjs from "dayjs";
import Pagination from "../pagination";
import { useTranslation } from "react-i18next";
import PerPageList from "../pagination/PerPageList";
import SearchInput from "../Search";
import { useLoading } from "../../contexts/LoadingContext";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const UserTable = () => {
  const columns = ["name", "surname", "email", "created_at", "role"];
  const [pagination, setPagination] = useState({
    take: 10,
    page: 1,
    totalPage: 0,
    links: [],
    total: 0,
    lastPage: 0,
    currentPage: 0,
    from: 0,
    to: 0,
  });
  const [data, setData] = useState([]);
  const { isLoading, setLoading } = useLoading();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [orderDirection, setOrderDirection] = useState<string | null>(null);

  const handleSort = (column: string) => {
    if (orderBy === column) {
      if (orderDirection === "asc") {
        setOrderDirection("desc");
      } else if (orderDirection === "desc") {
        setOrderBy(null);
        setOrderDirection(null);
      }
    } else {
      setOrderBy(column);
      setOrderDirection("asc");
    }
  };

  const getData = ({
    take = 10,
    page = 1,
    q = searchText,
    orderDirection = null,
  }: {
    take?: number;
    page?: number;
    q?: string;
    orderDirection?: string | null;
  }) => {
    setLoading(true);
    getUserList(take, page, q, orderDirection, orderBy).then((e) => {
      const data = e.data;

      const parsedData = data.map((item: any, index: number) => {
        return {
          ...item,

          email: item?.communication_info[0].content,
          created_at: dayjs(item?.communication_info[0].created_at).format(
            "DD/MM/YYYY"
          ),
          role: item?.roles[0].display_name.en,
        };
      });
      setSearchText(q);
      setPagination({
        take: e?.per_page,
        page: e.current_page,
        totalPage: e.last_page,
        links: e.links,
        lastPage: e.last_page,
        currentPage: e.current_page,
        total: e.total,
        from: e.from,
        to: e.to,
      });
      setLoading(false);
      setData(parsedData);
    });
  };

  useEffect(() => {
    getData({ orderDirection, page: pagination.page, take: pagination.take });
  }, [orderDirection]);

  console.log(orderBy, orderDirection);
  return (
    <div className="flex flex-col m-5 space-y-3 ">
      <div className="overflow-x-auto rounded-md shadow-lg bg-white">
        <div className="font-inter p-5">
          <h1 className="text-2xl"> {t("userList")}</h1>
        </div>

        <div className="flex flex-col md:flex-row font-inter space-y-2 md:space-y-0 p-5 border-b justify-between">
          <SearchInput onSearch={getData} pagination={pagination} />
          <PerPageList
            isLoading={isLoading}
            pagination={pagination}
            getData={getData}
          />
        </div>

        <div className="max-h-[500px] overflow-y-auto relative">
          <table className="min-w-full bg-white ">
            <thead className="sticky top-0 bg-white z-10">
              <tr>
                {columns.map((column, index) => (
                  <th
                    onClick={() => {
                      if (["name", "surname", "created_at"].includes(column)) {
                        handleSort(column);
                      }
                    }}
                    key={index}
                    className="py-5 px-5 border-b text-left font-medium"
                  >
                    <div className="flex items-center space-x-1 cursor-pointer w-[150px]">
                      <label className="cursor-pointer">{t(column)}</label>
                      {orderBy == column ? (
                        orderDirection == "asc" ? (
                          <FaArrowDown size={12} />
                        ) : (
                          <FaArrowUp size={12} />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((row, rowIndex) => (
                  <tr key={rowIndex} className="hover:bg-gray-100">
                    {columns.map((column, colIndex) => {
                      const value = row[column];

                      return (
                        <td key={colIndex} className="border-b px-5 p-2">
                          {value ? (
                            column === "role" ? (
                              <div
                                className={`${
                                  value === "Customer"
                                    ? "bg-green-600"
                                    : "bg-blue-600"
                                } border rounded-full text-center py-1 text-white w-[120px]`}
                              >
                                {value}
                              </div>
                            ) : (
                              <label
                                className={`${
                                  column === "email"
                                    ? "text-gray-400"
                                    : "text-black"
                                }`}
                              >
                                {value}
                              </label>
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex mt-5 h-10">
        <Pagination
          isLoading={isLoading}
          pagination={pagination}
          getData={getData}
        />
      </div>
    </div>
  );
};

export default UserTable;
