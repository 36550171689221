import { useTranslation } from "react-i18next";
import { MdLanguage } from "react-icons/md";

const Index = () => {
  const { i18n, t } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "tr" : "en");
  };

  return (
    <div
      className="flex cursor-pointer space-x-1 w-16"
      onClick={changeLanguage}
    >
      <MdLanguage size={24} color="blue" />
      <label className="font-bold cursor-pointer">
        {i18n.language === "tr" ? "TR" : "EN"}
      </label>
    </div>
  );
};

export default Index;
