import React from "react";

const PerPageList = ({
  pagination,
  getData,
  isLoading,
}: {
  pagination: {
    take: number | null;
    page: number | null;
    totalPage: number | null;
  };
  getData: any;
  isLoading: boolean;
}) => {
  const takeList = [1, 10, 25, 50, 100];

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = Number(event.target.value);
    if (!isLoading && pagination?.take !== selectedValue) {
      getData({ take: selectedValue, page: 1 });
    }
  };

  return (
    <div className="flex space-x-2">
      <select
        value={pagination.take || ""}
        onChange={handleChange}
        className="border  px-2 rounded-md cursor-pointer"
      >
        {takeList.map((item) => (
          <option key={item} value={item} className="text-black">
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PerPageList;
