import { useEffect, useState } from "react";
import Main from "../components/login/Main";
import { getTokenService, sendOtpService } from "../services/apiService";
import { toast } from "react-toastify";
import { getCookie } from "../helpers/cookieHelper";

const Login = () => {
  const [otp, setOtp] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isInitial, setIsInitial] = useState(true);

  const sendOtp = () => {
    if (email.length < 6) {
      toast.warning("Email Required");
      return;
    }

    setLoading(true);
    sendOtpService(email).then((data) => {
      if (data?.success) {
        setLoading(false);
        setSuccess(data?.success);
      }
    });
  };

  const signIn = () => {
    if (!otp || otp?.length !== 6) {
      toast.warning("OTP must be 6 characters");
      return;
    }
    setLoading(true);
    getTokenService(email, otp);
    setLoading(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      success ? signIn() : sendOtp();
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [email, otp]);

  useEffect(() => {
    if (getCookie("accesstoken")) {
      window.location.href = "/";
    }
    setIsInitial(false);
  }, []);

  if (isInitial) return null;

  return (
    <>
      <Main
        onClick={success ? signIn : sendOtp}
        otp={otp}
        setOtp={setOtp}
        email={email}
        setEmail={setEmail}
        success={success}
        setSuccess={setSuccess}
        loading={loading}
        onKeyDown={handleKeyDown}
      />
    </>
  );
};

export default Login;
