// axiosInstance.ts
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";
import { getCookie } from "../helpers/cookieHelper";
import { toast } from "react-toastify";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // API'nizin temel URL'si
  timeout: 10000, // İsteğin zaman aşım süresi (ms)
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Burada request öncesi yapılacak işlemleri belirleyebilirsin
    if (config.url !== "/login") {
      const token = getCookie("accesstoken");
      if (token) {
        config.headers.Authorization = `${token}`;
      }
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: any) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/login";
      return null;
    } else if (
      (error.response && error.response.status === 422) ||
      (error.response && error.response.status === 400)
    ) {
      toast.error(error?.response?.data?.message);
    } else {
      return null;
    }
  }
);

export default axiosInstance;
