import UserCard from "./user/Card";
import { IoIosMenu } from "react-icons/io";
import Language from "./language";
import { deleteCookie } from "../helpers/cookieHelper";
import { IoLogOut } from "react-icons/io5";
import { useTranslation } from "react-i18next";
const TopNav = ({ user, toggleMenu }: { user: any; toggleMenu: any }) => {
  const { t } = useTranslation();
  return (
    <div className="flex bg-white shadow-sm h-16 items-center justify-between px-5">
      <div onClick={toggleMenu} className="cursor-pointer">
        <IoIosMenu size={24} />
      </div>

      <div className="flex items-center space-x-2">
        <Language />
        <UserCard user={user} />
        <div
          className="flex space-x-3 ml-5 items-center text-red-600 cursor-pointer"
          onClick={() => {
            deleteCookie("accesstoken");
          }}
        >
          <div className="flex text-center items-center justify-center w-8 h-8 p-1 rounded-full">
            <IoLogOut size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
