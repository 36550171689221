import { IoMdAnalytics, IoIosSettings, IoIosApps } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { TbPackages } from "react-icons/tb";
import { PiInvoice } from "react-icons/pi";
import { PiStandardDefinition } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { RiUnsplashFill } from "react-icons/ri";
import Item from "./sidebar/Item";

const Sidebar = () => {
  const { t } = useTranslation();

  const menuItems = [
    {
      parent: {
        name: t("dashboard"),
        link: "/",
        icon: <IoMdAnalytics size={20} />,
      },
    },
    {
      parent: {
        name: t("packages"),
        link: "/packages",
        icon: <TbPackages size={24} />,
      },
    },
    {
      parent: {
        name: t("users"),
        link: "/users",
        icon: <CgProfile size={24} />,
      },
    },
    {
      parent: {
        name: t("providers"),
        link: "/providers",
        icon: <IoIosApps size={24} />,
      },
    },
    {
      parent: {
        name: t("invoices"),
        link: "/invoices",
        icon: <PiInvoice size={24} />,
      },
    },
    {
      parent: {
        name: t("settings"),
        link: "/settings",
        icon: <IoIosSettings size={24} />,
      },
    },

    {
      parent: {
        name: t("splash"),
        link: "/splash-screens",
        icon: <RiUnsplashFill size={24} />,
      },
    },
    {
      parent: {
        name: t("definitions"),
        link: "/definitions",
        icon: <PiStandardDefinition size={24} />,
        child: [
          {
            name: t("currencies"),
            query: "currencies",
          },
          {
            name: t("languages"),
            query: "languages",
          },
          {
            name: t("roles"),
            query: "roles",
          },
          {
            name: t("communicationMethods"),
            query: "communication-methods",
          },
          {
            name: t("addressTypes"),
            query: "address-types",
          },
          {
            name: t("agreementGroups"),
            query: "agreement-groups",
          },
          {
            name: t("countries"),
            query: "countries",
          },
          {
            name: t("cities"),
            query: "cities",
          },
          {
            name: t("states"),
            query: "states",
          },
          {
            name: t("regions"),
            query: "regions",
          },
          {
            name: t("timezones"),
            query: "timezones",
          },
        ],
      },
    },
  ];

  return (
    <div
      className="flex flex-col absolute md:relative text-gray-400 shadow-md space-y-6 pt-2 w-2/3  lg:w-1/4 bg-gray-900 pb-5 h-screen overflow-y-scroll"
      style={{
        transition: "transform 0.3s ease-in-out, width 0.3s ease-in-out",
      }}
    >
      <label className="text-2xl text-center ">logo</label>

      <hr className="border-t-2 border-gray-400" />
      <div className="flex flex-col space-y-8 ">
        {menuItems.map((item: any) => {
          return <Item item={item} />;
        })}
      </div>
    </div>
  );
};

export default Sidebar;
