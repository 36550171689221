import { toast } from "react-toastify";
import { setCookie } from "../helpers/cookieHelper";
import axiosInstance from "./axiosInstance";
export const sendOtpService = async (email: string) => {
  toast.info("Progressing");
  try {
    const response = await axiosInstance.post("/auth/otp", {
      username: email,
    });

    if (response && response.data?.success) {
      toast.success("Successful");
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const getTokenService = async (email: string, code: string) => {
  toast.info("Progressing");
  try {
    const response = await axiosInstance.post("/auth/token", {
      username: email,
      password: code,
    });

    if (response && response.data) {
      toast.success("Successful");
      const { token_type, expires_in, access_token } = response.data;
      setCookie("accesstoken", `${token_type} ${access_token}`, expires_in);
      window.location.href = "/";
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const getMe = async () => {
  try {
    const response = await axiosInstance.get("/auth/me");
    if (response && response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const getUserList = async (
  take: number,
  page: number,
  q: string,
  orderDirection: string | null,
  orderBy: string | null
) => {
  try {
    // URL oluşturma
    let url = `/users?page=${page}&limit=${take}&q=${q}`;

    // Eğer orderBy null değilse, URL'ye ekle
    if (orderBy) {
      url += `&order_by=${orderBy}`;
    }

    // Eğer orderDirection null değilse, URL'ye ekle
    if (orderDirection) {
      url += `&order_direction=${orderDirection}`;
    }

    const response = await axiosInstance.get(url);

    if (response && response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
