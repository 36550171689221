import React from "react";
import { ClimbingBoxLoader } from "react-spinners";

const Loading = () => {
  return (
    <div className="flex items-center justify-center z-20 absolute w-full h-full bg-gray-700 opacity-40 ">
      <ClimbingBoxLoader
        color={"#0000FF"}
        loading={true}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loading;
