import React from "react";

type Props = {
  title: string;
  color: string;
  onClick: any;
  disabled: boolean;
};

const Button: React.FC<Props> = ({ title, color, onClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`border p-2 text-md font-semibold ${
        disabled ? "bg-gray-400" : color
      } rounded-md text-white`}
    >
      {title}
    </button>
  );
};

export default Button;
