import { PaginationLink } from "../../types/settings";
import { FaCircleRight, FaCircleLeft } from "react-icons/fa6";

const index = ({
  pagination,
  getData,
  isLoading,
}: {
  pagination: {
    take: number;
    page: number;
    totalPage: number | null;
    links: PaginationLink[];
    total: number;
    lastPage: number;
    currentPage: number;
    from: number;
    to: number;
  };
  getData: any;
  isLoading: boolean;
}) => {
  return (
    <div className="flex px-2 justify-between w-full">
      <div className="font-bold">
        Showing {pagination.from} to {pagination.to} of {pagination.total}{" "}
        Records
      </div>
      <div className="flex space-x-2">
        {pagination.links.map((link, index) => {
          if (link.label === "...") {
            return (
              <span
                key={index}
                className="px-4 py-2  text-gray-500 rounded-full"
              >
                {link.label}
              </span>
            );
          }
          return (
            <button
              key={index}
              onClick={() => {
                if (link.url && !isLoading) {
                  // Extract page number from the URL
                  const urlParams = new URLSearchParams(
                    new URL(link.url).search
                  );
                  const page = parseInt(urlParams.get("page") || "1");
                  getData({ take: pagination.take, page });
                }
              }}
              className={`text-center  rounded-full w-10 ${
                link.active ? "bg-blue-500 text-white" : ""
              }`}
            >
              {index == 0 ? (
                <FaCircleLeft size={24} />
              ) : index + 1 !== pagination.links.length ? (
                link.label
              ) : (
                <FaCircleRight size={24} />
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default index;
