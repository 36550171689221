import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import TopNav from "../TopNav";
import { getMe } from "../../services/apiService";
import { SettingsInterface } from "../../types/settings";
import storageHelper from "../../helpers/storageHelper";
import { Outlet } from "react-router-dom";
import Loading from "../loading/Loading";
import { useLoading } from "../../contexts/LoadingContext";

const Layout: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [settings, setSettings] = useState<SettingsInterface | null>(null);

  const { isLoading, setLoading } = useLoading();

  const toggleMenu = () => {
    if (settings) {
      const newState: SettingsInterface = {
        darkMode: settings.darkMode,
        isMenuOpen: !settings.isMenuOpen,
      };
      setSettings(newState);
      storageHelper.set("settings", newState);
    }
  };

  const getUser = async () => {
    try {
      const user = await getMe();
      setUser(user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const savedSettings = storageHelper.get<SettingsInterface>("settings");
    if (savedSettings) {
      setSettings(savedSettings);
    } else {
      setSettings({ darkMode: false, isMenuOpen: true });
    }
    getUser();
  }, []);

  if (!user) return null;

  return (
    <div className="flex min-h-screen bg-gray-100">
      {settings?.isMenuOpen && <Sidebar />}
      {isLoading && <Loading />}
      <div className="flex flex-col w-full">
        <TopNav toggleMenu={toggleMenu} user={user} />
        {/* Burada Outlet kullanarak iç rotaları render ediyoruz */}
        <main className="flex-1">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
