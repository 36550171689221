import Input from "../input";
import Header from "../input/Header";
import Button from "../button";
import OtpInput from "react-otp-input";
import Language from "../language";
import { useTranslation } from "react-i18next";
const Main = ({
  otp,
  setOtp,
  setEmail,
  email,
  onClick,
  success,
  setSuccess,
  loading,
  onKeyDown,
}: {
  otp: any;
  setOtp: any;
  email: any;
  setEmail: any;
  onClick: any;
  success: boolean;
  setSuccess: any;
  loading: boolean;
  onKeyDown: any;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center border-2 min-h-screen w-full ">
      <div className=" flex flex-col justify-between py-10  w-1/3 p-5 space-y-5 rounded-sm bg-white shadow-lg relative">
        <div className="absolute top-3 right-0">
          <Language />
        </div>
        <div className="flex flex-col  items-center space-y-10 ">
          <div className="flex flex-col mb-20 space-y-5 items-center">
            <label className="h-20 w-20 border rounded-full text-center pt-7 bg-blue-600 text-white">
              LOGO
            </label>
            <label className="text-xl text-center font-bold">
              Company Name
            </label>
          </div>

          <div className="flex flex-col w-full pb-10 space-y-5">
            <div className="flex flex-col w-full">
              <Input
                onKeyDown={onKeyDown}
                value={email}
                onChange={(e: any) => {
                  setSuccess(false);
                  setOtp(null);
                  setEmail(e.target.value);
                }}
                name={t("email")}
                placeholder="name@example.com"
              />
            </div>
            {success && (
              <div className="flex flex-col  w-full items-start justify-start space-y-5">
                <Header name={t("code")} />
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => (
                    <input {...props} onKeyDown={onKeyDown} />
                  )}
                  inputStyle={{
                    width: "50px",
                    height: "50px",
                    margin: "0px 2px",
                    fontSize: "18px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <Button
          disabled={loading}
          onClick={onClick}
          title={success ? t("signIn") : t("sendotp")}
          color="bg-blue-600"
        />
      </div>
    </div>
  );
};

export default Main;
