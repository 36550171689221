import React, { useState } from "react";
import { FaArrowRight, FaArrowUp, FaArrowDown } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const Item = ({ item }: { item: any }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("section");
  return (
    <div className="flex flex-col" key={item.parent.name}>
      <div
        className={`${
          location.pathname === item.parent.link && "text-white"
        } flex mx-5 cursor-pointer items-center justify-between hover:text-white `}
        onClick={() => {
          if (item?.parent.child) {
            setIsOpen(!isOpen);
          } else {
            navigate(item.parent.link);
          }
        }}
      >
        <div className="flex items-center  space-x-3">
          <div className="flex text-center items-center justify-center w-8 h-8 bg-slate-800 p-1 rounded-full">
            {item.parent.icon}
          </div>
          <label className="text-sm cursor-pointer ">{item.parent.name}</label>
        </div>

        {item?.parent?.child ? (
          isOpen ? (
            <FaArrowUp size={12} />
          ) : (
            <FaArrowDown size={12} />
          )
        ) : (
          <FaArrowRight size={12} />
        )}
      </div>
      {item?.parent.child &&
        isOpen &&
        item.parent.child.map((child: any) => {
          return (
            <div
              onClick={() => {
                navigate(`${item.parent.link}?section=${child.query}`);
              }}
              className="flex  items-center px-16 py-1"
            >
              <label
                className={`${
                  query == child.query && "text-white"
                } text-sm cursor-pointer `}
              >
                {child.name}
              </label>
            </div>
          );
        })}
    </div>
  );
};

export default Item;
